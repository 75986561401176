import React, { useEffect } from 'react'
import analytics from "../../services/mixpanel";
import MIXPANEL_KEYS from "../../services/mixpanel/keys"

import background from "../../assets/background.png"
import icon from "../../assets/joinRoom/icon.png"
import smallIcon from "../../assets/joinRoom/small.png"

interface JoinRoomProps {
  userId: string
  username: string
  onJoin: () => void
}

const JoinRoom = ({userId, username, onJoin}: JoinRoomProps) => {

  useEffect(() => {
    if (userId) {
      console.log({userId})
      analytics.track(MIXPANEL_KEYS.WEB_VIEWED_PAGE)
      analytics.track(MIXPANEL_KEYS.WEB_RECEIVED_PAGEVIEW, { distinct_id: userId })
    }
  }, [userId])

  const onCreate = () => {
    analytics.track(MIXPANEL_KEYS.WEB_RECEIVED_DOWNLOAD, { distinct_id: userId })
    window.open("https://apps.apple.com/us/app/kickback-group-video-chat/id6547833850", "_blank", "noreferrer");
  }

  return (
      <div style={styles.container}>
        <div style={styles.buttonBackground} className='flex-col justify-between'>
          <img src={icon} alt="icon" className="w-[65px] h-[65px]"/>
          <div style={styles.buttonText} className="text-white text-center font-bold">
            Join my group video call!
          </div>
          <div style={styles.username} className='text-white text-center'>@{username}</div>
          <button
            onClick={onJoin}
            style={styles.button}
            className="text-white text-center text-xl font-bold w-[190px] h-[50px] rounded-full flex flex-row justify-center items-center"
          >
            <img src={smallIcon} alt="icon" className="w-[36px] h-[28px]"/>
            Join
          </button>

          <button
            onClick={onCreate}
            style={styles.getAppButton}
            className='absolute bottom-10 text-black text-center text-xl font-bold w-[300px] h-[55px] bg-white rounded-full flex flex-row justify-center items-center'
          >
            Create your own!
          </button>
        </div>
      </div>
    )
}

const styles = {
  container: {
    height: "100vh",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
  },
  buttonBackground: {
    backgroundColor: "black",
    height: "337px",
    width: "300px",
    borderRadius: 20,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
  },
  username: {
    fontFamily: "Graphik-Medium",
    fontSize: 16,
    opacity: 0.5,
  },
  button: {
    backgroundColor: "#02FF94",
    fontFamily: "Graphik-Bold",
  },
  buttonText: {
    fontFamily: "Graphik-Semibold",
    fontSize: 24,
  },
  getAppButton: {
    fontFamily: "Graphik-Bold",
    fontSize: 18,
  }
}

export default JoinRoom